import React, { useState } from "react";
import { Affix, Form, Input, Button, message } from "antd";
import ProfileItem from "./ProfileItem";
import PaymentItem from "./PaymentItem";
import AddressItem from "./AddressItem";
import ExpertisesItem from "./ExpertInfo";
import EducationItem from "./EducationItem";
import CertificateItem from "./CertificateItem";
import SeansSettins from "./SeansSettings";
import IBAN from "iban";
import dayjs from "dayjs";
import { ExpertService } from "../../../../api";

import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

export function FormItem({ formItem }) {
  return (
    <Form.Item
      rules={[
        {
          // required: formItem?.required === "0" ? false : true,
          // message: `Lütfen ${formItem.label} alanını doldurun.`,
        },
        ...formItem.rules,
      ]}
      label={formItem.label}
      className="col-12 col-md-6 col-lg-3"
      name={formItem.name}
      initialValue={formItem.defaultValue}
    >
      {formItem.component === "textarea" ? (
        <Input.TextArea
          size="large"
          placeholder={formItem.label + " Yazınız.."}
          {...formItem}
        />
      ) : (
        <Input
          size="large"
          placeholder={formItem.label + " Yazınız.."}
          {...formItem}
        />
      )}
    </Form.Item>
  );
}
const ProfileExpert = ({ action }) => {
  const { expertId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const expertState = useSelector((state) => state.newExpert);
  const navigation = useNavigate();
  const isValidateForm = (body) => {
    let msgDetail = "";
    if (!body.educationInfo.length) {
      msgDetail = "Lütfen eğitim listesinden eğitim ekleyiniz.";
    }

    if (!msgDetail.length) return;
    throw { msgDetail };
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      let body = {
        ...values,
        educationInfo: expertState.educations.map(({ id, ...rest }) => rest),
        certificateList: expertState.certificates.map(
          ({ id, ...rest }) => rest
        ),
        address: {
          address: values.address,
          addressDirection: values.addressDirection,
          city: values.city,
          district: values.district,
          street: values.street,
        },
        phone: `${values.phone}`,
        iban: `TR${values.iban}`,
        sessionUnitPrice: Number(values.sessionUnitPrice),
        overallServiceMinutes:
          Number(dayjs(values.overallServiceMinutes).format("mm")) || 0,
      };
      delete body.addressDirection;
      delete body.city;
      delete body.district;
      delete body.street;
      isValidateForm(body);

      switch (action) {
        case "create":
          var response = await ExpertService.createExpert({ body });

          break;
        case "put":
          body.phoneNumber = `${values.phone}`;
          body.price = parseInt(values.sessionUnitPrice);
          body.expertises = body.expertises.map((item) => item?.value || item);
          delete body.phone;
          delete body.sessionUnitPrice;
          var response = await ExpertService.putExpert({ expertId, body });
          break;

        default:
          break;
      }
      if (response.data.status === "failed") {
        message.error(response.data.msgDetail);
        return;
      }

      navigation("/experts");
    } catch (error) {
      console.log(error);
      message.error(
        JSON.stringify(error?.response.data.msgDetail) +
          ` ${error?.response?.data?.subErrors?.map(
            (item) => item.field + item.message
          )}`
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError
        style={{ marginBottom: 50 }}
      >
        <PaymentItem />
        <ProfileItem />
        <AddressItem form={form} />
        <ExpertisesItem />
        <SeansSettins />
        <EducationItem />
        <CertificateItem />
        <Affix offsetBottom={0}>
          <Form.Item>
            <Button
              loading={loading}
              size="large"
              className="w-100"
              htmlType="submit"
              type="primary"
              style={{ marginBottom: 50 }}
            >
              {action === "put"
                ? "Güncelle"
                : action === "create"
                ? "Ekle"
                : ""}
            </Button>
          </Form.Item>
        </Affix>
      </Form>
    </>
  );
};
export default ProfileExpert;
