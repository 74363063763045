import { useSelector } from "react-redux";
import { Card, Form, Select, Input } from "antd";
const Payment = () => {
  const expertDetail = useSelector((state) => state.newExpert.expertDetail);
  //
  const generateIbanForInitial = () => {
    try {
      const ibanString = expertDetail?.iban?.split("TR")[1];
      return ibanString;
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  return (
    <Card className="mb-3 " title="Ödeme Detay">
      <div className="row">
        <Form.Item
          label="IBAN"
          rules={[
            {
              min: 0,
              max: 24,
            },
          ]}
          className="col-12 col-md-6 col-lg-6"
          name={"iban"}
          initialValue={generateIbanForInitial()}
        >
          <Input
            placeholder="IBAN Yazınız"
            size="large"
            type="number"
            name="iban"
            addonBefore={
              <Form.Item
                rules={[{ required: true, message: "Birim Seçiniz." }]}
                className="mb-0"
                name="currencyCode"
                initialValue={"TR"}
              >
                <Select
                  size="large"
                  defaultValue={"TR"}
                  placeholder="Kod"
                  style={{ width: 140 }}
                  options={[
                    { value: "TR", label: "TR" },
                    { value: "GB", label: "GB" },
                  ]}
                />
              </Form.Item>
            }
            defaultValue={expertDetail?.iban}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Lütfen Fiyat alanını doldurun." },
          ]}
          label="Fiyat"
          className="col-12 col-md-6 col-lg-6"
          name={"sessionUnitPrice"}
          initialValue={Number(expertDetail?.priceInfo?.price)}
        >
          <Input
            type="number"
            size="large"
            name="sessionUnitPrice"
            defaultValue={expertDetail?.priceInfo?.price}
            placeholder="Fiyat Yazınız"
            addonAfter={
              <Form.Item
                rules={[{ required: true, message: "Birim Seçiniz." }]}
                className="mb-0"
                name="currencyCode"
                initialValue={expertDetail?.priceInfo?.currencyCode}
              >
                <Select
                  size="large"
                  defaultValue={expertDetail?.priceInfo?.currencyCode}
                  placeholder="Kod"
                  style={{ width: 140 }}
                  options={[
                    { value: "TRY", label: "TRY" },
                    { value: "AZN", label: "AZN" },
                    { value: "GBP", label: "GBP" },
                  ]}
                />
              </Form.Item>
            }
          />
        </Form.Item>
      </div>
    </Card>
  );
};
export default Payment;
